<template>
    <v-row>
        <v-col cols="5">
            <v-card elevation="4">
                <v-card-title>
                    {{ availableClusters.length }} Clusters Disponibili
                </v-card-title>
                <v-text-field
                    v-model="availablesearch"
                    label="Ricerca Cluster"
                    append-icon="mdi-magnify"
                    class="mx-4"
                    filled
                ></v-text-field>

                <ClustersDragList
                    :clusters.sync="availableClusters"
                    :search="availablesearch"
                    @currentSelection="availableChanged"
                    :height="'335'"
                ></ClustersDragList>
            </v-card>
        </v-col>
        <v-col cols="2" align-self="center">
            <v-row>
                <v-col cols="12" class="d-flex justify-center">
                    <v-btn fab icon large @click="removeAll" :disabled="currentSelected.length === 0">
                        <v-icon x-large>mdi-rewind</v-icon></v-btn
                    >
                </v-col>
                <v-col cols="12" class="d-flex justify-center" :disabled="currentAvailable.length === 0">
                    <v-btn fab icon large @click="addAll">
                        <v-icon x-large>mdi-fast-forward</v-icon></v-btn
                    >
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="5" :class="{'changed': changed}">
            <v-card elevation="4">
                <v-card-title>
                    {{ promoClusters.length }} Clusters Applicati
                </v-card-title>
                <v-text-field
                    v-model="appliedsearch"
                    label="Ricerca Cluster"
                    append-icon="mdi-magnify"
                    class="mx-4"
                    filled
                ></v-text-field>

                <ClustersDragList
                    :clusters.sync="promoClusters"
                    :search="appliedsearch"
                    @currentSelection="appliedChanged"
                    :height="'335'"
                ></ClustersDragList>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as clusterActions from "../../../store/actions/cluster";

export const ClustersDragList = () => import("../../../components/promo/PromoClustersDragList");

export default {

    props: ['farmId', 'clusters'],

    data: () => ({
        promoClusters: [],
        availableClusters: [],
        appliedsearch: "",
        availablesearch: "",
        mounted: false,
        changed: false,
        currentAvailable: [],
        currentSelected: []
    }),

    components: {
        ClustersDragList
    },

    watch: {
        promoClusters: function(newVals, oldVals) {
            // console.log(this.mounted);
            if (this.mounted) {
                // console.log(newVals, oldVals);
                const clusters = newVals.map(val => {
                    return val.IDGruppo;
                });
                // console.log(clusters)
                this.$emit("update:clusters", clusters);
            }
        },
    },

    computed: {
        ...mapState({
            farmClusters: state => state.clusterModule.clusters,
            loaded: state => state.clusterModule.loaded
        })

        // dragOptions() {
        //     return {
        //         animation: 0,
        //         group: "description",
        //         disabled: !this.editable,
        //         ghostClass: "ghost"
        //     };
        // }
    },

    methods: {
        ...mapActions({
            loadClusters: clusterActions.CLUSTER_GET_CLUSTERS
        }),

        async getClusters(farmId) {
            try {
                if (!this.loaded) {
                    const state = await this.loadClusters(farmId);
                }
                return this.farmClusters;
            } catch (err) {
                console.error(err);
            }
        },

        availableChanged(available) {
            //console.log('siamo filtrati e siamo', available)
            this.currentAvailable = available;

        },

        appliedChanged(applied) {
            console.log('siamo filtrati e siamo', applied.length)
            this.currentSelected = applied;
        },

        addAll() {
            //console.log('premuto add all', this.currentAvailable.length)
            this.promoClusters = [...this.promoClusters, ...this.currentAvailable];
            const ids = this.currentAvailable.map(x => x.IDGruppo);
            this.availableClusters = this.availableClusters.filter(cluster => {
                return !ids.includes(cluster.IDGruppo)
            })
        },

        removeAll() {
            //console.log('premuto remove all')
            this.availableClusters = [...this.availableClusters, ...this.currentSelected];
            const ids = this.currentSelected.map(x => x.IDGruppo);
            this.promoClusters = this.promoClusters.filter(cluster => {
                return !ids.includes(cluster.IDGruppo)
            })
        },

        async convertClusters() {
            //console.log(this.clusters)
            this.mounted = false;
            const farmClusters = await this.getClusters(this.farmId);
            this.promoClusters = farmClusters.filter(cluster => {
                return this.clusters.includes(cluster.IDGruppo);
            });
            this.availableClusters = farmClusters.filter(cluster => {
                return !this.clusters.includes(cluster.IDGruppo);
            });
            setTimeout(() => {
                this.mounted = true;
            }, 0);
        }
    },

    mounted() {
        this.$nextTick(async () => {
            await this.convertClusters()
        });
    }
}
</script>

<style>

</style>
